import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import Layout from "../../components/Layout"
import PageSplash from "../../components/PageSplash/PageSplash"
import PreHeading from "../../components/PreHeading"
import IconRow from "../../components/IconRow/IconRow"
import IconRowItem from "../../components/IconRow/IconRowItem"
import { ValueProp, ValuePropHeader } from "../../components/ValueProp/ValueProp"
import { MediaAndText } from "../../components/ImageAndText/ImageAndText"

import LoginIcon from "../../images/icons/log-in.inline.svg"
import PieIcon from "../../images/icons/pie.inline.svg"
import ChevRightIcon from "../../images/icons/chev-right.inline.svg"
import MaximizeIcon from "../../images/icons/maximize.inline.svg"
import AwardIcon from "../../images/icons/award.inline.svg"
import ReloadIcon from "../../images/icons/reload.inline.svg"
import LayersIcon from "../../images/icons/layers.inline.svg"
import GlobeIcon from "../../images/icons/globe.inline.svg"
import TabletIcon from "../../images/icons/tablet.inline.svg"
import FilterIcon from "../../images/icons/filter.inline.svg"
import DownloadCloudIcon from "../../images/icons/download-cloud.inline.svg"
import MessageIcon from "../../images/icons/message.inline.svg"
import CodepenIcon from "../../images/icons/codepen.inline.svg"
import FeatherIcon from "../../images/icons/feather.inline.svg"
import UsersIcon from "../../images/icons/users.inline.svg"

import CustomerSlider from "../../components/CustomerSlider"
import ScreenshotEn from "../../images/pages/products/sales/screenshot-en"
import Seo from "../../components/Seo"

import Style from "./sales.module.css"
import { SupportEn } from "../../components/Support"
import { StartNowEn } from "../../components/StartNow"
import { PageType } from "../../index"
import ProductNav from "../../components/ProductNav/ProductNav"

const SalesEn: PageType = ({ location }) => {
    const {
        splash,
    } = useStaticQuery(graphql`
        query SalesEn {
            splash: file(relativePath: { eq: "sales.jpg" }) {
                childImageSharp {
                    fluid(maxWidth: 1440, maxHeight: 680) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
        }
    `)

    return <Layout location={location}>
        <Seo
            title="Sales representatives grown into brand ambassadors"
        />
        <ProductNav />
        <PageSplash
            preHeading="Empower your Spokesperson"
            heading="Create Brand Ambassadors"
            cta="Explore"

            image={splash}
        />
        <span aria-hidden id="content" />
        <ValueProp>
            <ValuePropHeader
                preHeading="Sales"
                heading="The DNA of successful selling is not about selling anymore, but excelling in understanding."
            >
                <p>
                    Empower your spokesperson with a consistent, retrievable in depth product knowledge that can hardly be referred to in manuals. Capitalize your expertise to your customer in the most outstanding way that makes them feel unique by offering an excellent service. It is in this juncture, products with excessive explanatory needs become appealing commodities. Sales representatives, partners and agents turn into brand ambassadors whilst customers convert into followers.
                </p>
            </ValuePropHeader>

            <IconRow>
                <IconRowItem icon={<LoginIcon />}>
                    Facilitate products with explanatory needs
                </IconRowItem>
                <IconRowItem icon={<PieIcon />}>
                    Stay Momentous
                </IconRowItem>
                <IconRowItem icon={<ChevRightIcon />}>
                    Maximize Visibility
                </IconRowItem>
                <IconRowItem icon={<MaximizeIcon />}>
                    Corporate Branding
                </IconRowItem>
            </IconRow>
        </ValueProp>
        <section className="section-x-margin">
            <div className="flex flex-wrap desktop:flex-no-wrap items-center max-w-6xl px-4 m-auto">
                <div className="w-full desktop:w-2/3 m-auto">
                    <PreHeading className="mb-4">Analytics</PreHeading>
                    <h2>Measurable Output for your Performances</h2>
                </div>
                <div className="w-full desktop:w-1/3 m-auto mb-0">
                    <p className="mb-0">
                        Quadio’s Live-analytics indicate your staffs’ actual level of knowledge by making it available regardless of time and place. In the same vein, activities and performances are made transparent providing a reliable basis of data for all decisions containing strategic and market relevance
                    </p>
                </div>
            </div>

            <div className={Style.statisticsImage} />
        </section>
        <section className="section-x-margin">
            <MediaAndText
                noImageLimit
                imageClassName="tablet:px-12 mobile:px-4"
                image={<ScreenshotEn className="w-full pointer-events-none" />}
            >
                <PreHeading>Gamification</PreHeading>
                <h2>Game Spirit, Rewards and Incentives</h2>
                <p>
                    Research has clearly defined the impact of advertising in a nutshell:<br />
                    Create curiosity and imply game spirit coupled with incentives! That is the key to remain momentous. Increase a unique brand experience with QUADIO, the most effective marketing tool for your actively participating employees.
                </p>
                <ul className="list-blue-bullet mt-8">
                    <li>Effective Methods</li>
                    <li>Application of game design principles</li>
                    <li>Scientifically proven</li>
                </ul>
            </MediaAndText>
        </section>
        <SupportEn>
            <li>Project assistance and strategy focused consulting</li>
            <li>Traffic generating marketing tactics</li>
            <li>Setting –up tailored product specific trainings</li>
            <li>Requirements Engineering (RE)</li>
            <li>Introduction, operation and technical support</li>
            <li>Accreditation and certification</li>
            <li>Success analysis</li>
            <li>Individual developments</li>
        </SupportEn>
        <section className="section-x-margin section-x-padding">
            <PreHeading>Overview</PreHeading>
            <h2>Features</h2>

            <IconRow className="mt-16 mobile:mt-12" fluid>
                <IconRowItem icon={<LayersIcon />}>
                    Quiz based Trainings
                </IconRowItem>
                <IconRowItem icon={<PieIcon />}>
                    Data Analysis
                </IconRowItem>
                <IconRowItem icon={<GlobeIcon />}>
                    Online Coaching
                </IconRowItem>
                <IconRowItem icon={<TabletIcon />}>
                    Anywhere at Anytime
                </IconRowItem>
                <IconRowItem icon={<ReloadIcon />}>
                    Programmed Certificates
                </IconRowItem>
                <IconRowItem icon={<FilterIcon />}>
                    News
                </IconRowItem>
                <IconRowItem icon={<DownloadCloudIcon />}>
                    Download Option for Information Materials
                </IconRowItem>
                <IconRowItem icon={<AwardIcon />}>
                    Attractive Competitions and Activities
                </IconRowItem>
                <IconRowItem icon={<MessageIcon />}>
                    Voting & Feedback
                </IconRowItem>
                <IconRowItem icon={<CodepenIcon />}>
                    Live Data and Assessment
                </IconRowItem>
                <IconRowItem icon={<FeatherIcon />}>
                    Content Management System
                </IconRowItem>
                <IconRowItem icon={<UsersIcon />}>
                    Customer Relationship Management
                </IconRowItem>
            </IconRow>
        </section>
        <section className="section-x-margin">
            <CustomerSlider />
        </section>
        <StartNowEn />
    </Layout>
}

export default SalesEn
